/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,200&display=swap');

@import '@angular/material/theming';
@include mat-core();

$custom-app-primary: mat-palette($mat-green);
$custom-app-secondary: mat-palette($mat-blue, A200, A400, 700);
$custom-app-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-app-primary, $custom-app-secondary, $custom-app-warn);

@include angular-material-theme($custom-theme);

body {
  font-family: 'Poppins', sans-serif !important;
}

ion-menu-button {
  color: #30A949 !important;
}

.error{
  height: 50px;
  border: 1px solid #30A949 !important;
  border-radius: 5px;
  background-color: #30a9481c !important;
  color: #30A949 !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  margin-bottom: 15px;
  ion-icon {
    margin-left: 10px;
  }
}
